import React from 'react'
import PropTypes from 'prop-types'
import Icon from '@material-ui/core/Icon'
import style from './Tweet.module.scss'

const Tweet = ({ color, engagement_gain, name, icon, tweet_id, index }) => {
  return (
    <div className={style.wrap}>
      <header style={{background: color}}>
        <span className={style.position} style={{color: color}}>
          # <span>{index + 1}</span>
        </span>
        <span className={style.retweets}>
          {numberFormat(engagement_gain)}
          <Icon>whatshot</Icon>
        </span>
        <span className={style.name}>{name}</span>
        <span className={style.icon}>
          <Icon style={{color: color, fontSize: '30px'}}>{icon.toLowerCase()}</Icon>
        </span>
      </header>
      <div className={style.content}>
        <blockquote className="twitter-tweet">
        <a href={"https://twitter.com/x/status/" + tweet_id}></a>
        </blockquote>
      </div>
      <div className={style.megaNumber}>{index + 1}</div>
    </div>
  )
}

const numberFormat = num => {
  return Math.abs(num) > 999 ?
    Math.round(Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) ) + 'k' :
    Math.sign(num)*Math.abs(num)
}

const dateFormat = date => {
  const d = new Date(date)
  const month = new Intl.DateTimeFormat('en-US', {month: 'long'}).format(d)
  return `${month} ${d.getDate()}, ${d.getFullYear()}`
}

const nameFormat = url => {
  return `@${url.split('/')[3]}`
}

const textFormat = text => {
  const regex = /(https?:\/\/[^\s]+)/g
  return text.replace(regex, '<a href="$1">$1</a>')
}

Tweet.propTypes = {
  ////color: PropTypes.string.isRequired,
  // created_at: PropTypes.string.isRequired,
  ////icon: PropTypes.string.isRequired,
  // index: PropTypes.number.isRequired,
  ////name: PropTypes.string.isRequired,
  // retweet_count: PropTypes.number.isRequired,
  // text: PropTypes.string.isRequired,
  // url: PropTypes.string.isRequired

  color: PropTypes.string.isRequired,
  comments: PropTypes.number.isRequired,
  engagement_gain: PropTypes.number.isRequired,
  icon: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  likes: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  shares: PropTypes.number.isRequired,
  theme_id: PropTypes.number.isRequired,
  // time_bucket: 
  topic_id: PropTypes.number.isRequired,
  tweet_id: PropTypes.string.isRequired
}

export default Tweet
