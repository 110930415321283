import React from 'react'
import PropTypes from 'prop-types'
import Icon from '@material-ui/core/Icon'
import style from './Filters.module.scss'

const Filters = ({ teams, activeTeams, callback }) => {
  return (
    <div className={style.wrap}>
      <h3>Filter your feed</h3>
      {teams.map(team => {
        const isActive = activeTeams.find(activeTeam => activeTeam.value === team.id)
        const className = isActive ? [style.item, style.itemActive] : [style.item]
        const teamColor = isActive ? {color: team.color} : {}
        return (
          <button key={team.id} className={className.join(' ')} style={teamColor} onClick={() => callback(team)}>
            <Icon>
              {team.icon.toLowerCase()}
            </Icon>
            {team.team_name}
          </button>
        )
      })}
    </div>
  )
}

Filters.propTypes = {
  teams: PropTypes.array.isRequired,
  activeTeams: PropTypes.array.isRequired,
  callback: PropTypes.func.isRequired
}

export default Filters
