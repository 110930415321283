import React from 'react'
import style from './SiteFooter.module.scss'

const SiteFooter = () => {
  return (
    <footer className={style.wrap}>
      <ul>
        <li>
          <a href="https://us.mullenlowe.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>
        </li>
        <li>
          <a href="https://us.mullenlowe.com/ccpa-privacy-notice/" target="_blank" rel="noopener noreferrer">
            CCPA Privacy Notice
          </a>
        </li>
      </ul>
    </footer>
  )
}

export default SiteFooter
