import React from 'react'
import style from './Spinners.module.scss';

export const MiniSpinner = ({ color = 'black' }) => {
  return (
    <div className={style.miniSpinner} >
      <div style={{borderColor: `${color} transparent transparent transparent`}}></div>
      <div style={{borderColor: `${color} transparent transparent transparent`}}></div>
      <div style={{borderColor: `${color} transparent transparent transparent`}}></div>
      <div style={{borderColor: `${color} transparent transparent transparent`}}></div>
    </div>
  )
}

// TODO: move handleLoading component here
