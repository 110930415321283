import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Tweet from './Tweet'
import style from './Tweets.module.scss'

const VISIBLE_INCREMENT = 5

const Tweets = ({ tweets }) => {
  const [visible, setVisible] = useState(VISIBLE_INCREMENT)
  console.log(tweets);

  useEffect(() => {
    if (window.twttr) {
      window.twttr.widgets.load()
    }
  })

  return (
    <>
      {tweets
        .slice(0, visible)
        .map((tweet, i) => <Tweet {...tweet} index={i} key={tweet.tweet_id} />)
      }
      {visible < tweets.length && (
        <button className={style.loadMore} onClick={() => setVisible(visible + VISIBLE_INCREMENT)}>Load More</button>
      )}
    </>
  )
}

Tweets.propTypes = {
  tweets: PropTypes.array.isRequired
}

export default Tweets
