import React, { useState } from 'react'
import Signin from '../auth/Signin'
import Forgot from '../auth/Forgot'

const Login = () => {
  const [forgot, setForgot] = useState(false)
  return (
    <section>
      { forgot ? 
        <Forgot callback={() => setForgot(false)}/> : 
        <Signin callback={() => setForgot(true)} />
      }
    </section>
  )
}

export default Login
