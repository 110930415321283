import React, { useState } from 'react'
import { BrowserRouter as Router, Redirect, Switch, Route } from 'react-router-dom'
import { Get } from 'react-axios'
import Sticky from 'react-stickynode'
import { Feeds, Colors } from './dbShims'
import { useAuth } from './auth/auth'
import { HandleError, HandleLoading, HandleDefault } from './components/xhrHelpers'
import Speedbag from './pages/Speedbag'
import Login from './pages/Login'
import NoMatch from './pages/NoMatch'
import SiteFooter from './components/SiteFooter'
import SiteHeader from './components/SiteHeader'
import { SPEEDBAG_DATA } from './MAGIC_URLS'
import './App.scss'

const App = () => {
  let auth = useAuth()

  const getActiveFeed = () => JSON.parse(sessionStorage.getItem('feed')) || feeds[0]
  const setActiveFeed = feed => {
    sessionStorage.setItem('feed', JSON.stringify(feed))
    setFeed(feed)
  }

  const feeds = auth.user ? auth.user.feeds : Feeds
  const [feed, setFeed] = useState(getActiveFeed())

  sessionStorage.setItem('feed', JSON.stringify(feed))

  const scrubData = data => {
    return data
      .filter(team => team.top_retweets.length) // Remove any teams that came in with no tweets
      .map((team, i) => Object.assign(team, {   // Count up total retweets per team, order by that
        total_count: team.top_retweets.reduce((total, tweet) => total += tweet.retweet_count, 0),
        color: Colors[i], // TODO: should come from DB
        icon: team.materials_icon || 'account_box'
      }))
      .sort((a, b) => b.total_count - a.total_count)
  }

  return (
    <Router>
      <Sticky innerZ={9999}>
        <SiteHeader feeds={feeds} active={feed} callback={setActiveFeed} />
      </Sticky>

      <main>
        <Switch>
          <Route exact path="/">
            <Get url={SPEEDBAG_DATA} params={{client_tag: feed.value}} key={feed.value}>
              {(error, response, isLoading, makeRequest, axios) => {
                if (error) {
                  return <HandleError makeRequest={makeRequest} {...error} />
                }
                else if (response !== null) {
                  return <Speedbag data={scrubData(response.data.twitter_data || [])} />
                }
                else if (isLoading) {
                  return <HandleLoading />
                }
                return <HandleDefault />
              }}
            </Get>
          </Route>
          <Route path="/login">
            {auth.user ? (
              <Redirect from='/login' to='/'/>
            ) : (
              <Login/>
            )}
          </Route>
          <Route path="*">
            <NoMatch/>
          </Route>
        </Switch>
      </main>

      <SiteFooter />
    </Router>
  )
}

export default App
