import React from 'react'
import Icon from '@material-ui/core/Icon'
import style from './BackToTop.module.scss'

const BackToTop = () => {
  const scroll = () => {
    window.scrollTo({top: 0, behavior: 'smooth'})
  }

  return (
    <div className={style.wrap}>
      <div className={style.inner} onClick={scroll}>
        <Icon>play_arrow</Icon>
        <div>Back To Top</div>
      </div>
    </div>
  )
}

export default BackToTop
