import style from './xhrHelpers.module.scss'

export const HandleError = ({ makeRequest, error }) => {
  return (
    <div>
      <p>Something bad happened:</p>
      <p>{error.message}</p>
      <button onClick={() => makeRequest({ params: { reload: true } })}>Retry</button>
    </div>
  )
}

export const HandleLoading = () => {
  return (
    <div className={style.wrap}>
      <svg className={style.spinner} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 119.12 119.12" width="300px" height="300px">
        <circle cx="59.56" cy="59.56" r="29.55"  fill="none" strokeWidth="6" strokeLinecap="round" className={style.path + ' ' + style.path1} />
        <circle cx="59.56" cy="59.56" r="34.41"  fill="none" strokeWidth="6" strokeLinecap="round" className={style.path + ' ' + style.path2} />
        <circle cx="59.56" cy="59.56" r="38.79"  fill="none" strokeWidth="6" strokeLinecap="round" className={style.path + ' ' + style.path4} />
        <circle cx="59.56" cy="59.56" r="43.43"  fill="none" strokeWidth="6" strokeLinecap="round" className={style.path + ' ' + style.path5} />
      </svg>
    </div>
  )
}

export const HandleDefault = () => {
  return (
    <div>Default message before request is made.</div>
  )
}

// const HandleContent = ({ makeRequest, data }) => {
//   console.log(data)
//   return (
//     <div>
//       Loaded!
//       <button onClick={() => makeRequest({ params: { refresh: true } })}>Refresh</button>
//     </div>
//   )
// }
