import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useAuth } from './auth'
import { MiniSpinner } from '../components/Spinners'

const Forgot = ({ callback }) => {
  let auth = useAuth()
  const [isLoading, setIsLoading] = useState(auth.user)
  const [message, setMessage] = useState(null)

  const handleSubmit = event => {
    event.preventDefault()
    const username = event.target.username.value

    if (username) {
      setIsLoading(true)
      auth.forgot(username)
        .then(res => {
          let message = res.data.error
          if (res.data.success) {
            message = `${res.data.success} to ${username}`
            event.target.username.value = ''
          }
          setIsLoading(false)
          setMessage(message)
          console.log(res.data, message)
        })
    } else {
      setMessage('Please enter a username')
    }
  }

  return (
    <div className="container">
      <h2>Forgot Password</h2>
      <p>Forgot password, we have forgot password today</p>
      <form onSubmit={handleSubmit}>
        <input type="text" name="username" placeholder="username" />
        <p>{message}</p>
        <button type="submit">
          {isLoading ? <MiniSpinner/> : 'Get Auth Code'}
        </button>
      </form>
      <p>
        Already have an account? <button onClick={callback}>Sign in</button>
      </p>
    </div>
  )
}

Forgot.propTypes = {
  callback: PropTypes.func.isRequired
}

export default Forgot
