import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useAuth } from '../auth/auth'
import { Feeds } from '../dbShims'
import PropTypes from 'prop-types'
import Select from 'react-select'
import Icon from '@material-ui/core/Icon'
import { MiniSpinner } from './Spinners'
import logo from '../images/logo-speedbag-main.png'
import { filterStyleHeader } from '../filterStyles'
import style from './SiteHeader.module.scss'
import { debounce } from '@material-ui/core'

const SiteHeader = ({ feeds, active, callback }) => {
  const history = useHistory()
  const auth = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [isLogin, setIsLogin] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  history.listen(location => {
    setIsLogin(location.pathname === '/login' ? true : false)
  })

  // useEffect(() => {
  //   const script = document.createElement('script');
  
  //   script.src = "https://platform.twitter.com/widgets.js";
  //   script.async = true;
  
  //   document.body.appendChild(script);
  
  //   return () => {
  //     document.body.removeChild(script);
  //   }
  // }, []);

  // toggle 'no-scroll' class on body depending if hamburger menu is open
  useEffect(() => {
    document.body.classList.toggle('no-scroll', isOpen)
  }, [isOpen])

  // automatically close hamburger if window is 768 or wider
  useEffect(() => {
    const handleResize = debounce(() => {
      if (window.innerWidth >= 768 && isOpen) {
        setIsOpen(false)
      }
    }, 300)
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [isOpen])

  function handleLogout() {
    setIsLoading(true)
    auth.signout().then(() => {
      setIsOpen(false)
      setIsLoading(false)
      callback(Feeds[0])
      history.push('/')
    })
  }

  function handleSelectChange(event) {
    setIsOpen(false)
    callback(event)
  }

  const menuClasses = [style.menu]
  if (isOpen) {
    menuClasses.push(style.menuOpen)
  }

  return (
    <header className={style.wrap}>
      <div className={style.container}>
        <Link to="/" className={style.branding}>
          <img src={logo} alt="Speedbag logo" height="60" />
          <span className={style.name}>
            <strong>Speedbag</strong>
          </span>
        </Link>

        { auth.user && (
          <div>
            <button className={style.logout} onClick={handleLogout}>
              {isLoading ? <MiniSpinner color='white'/> : <span title="Logout"><Icon>logout</Icon> logout</span>}
            </button>

            <span className={style.hamburger} onClick={() => {setIsOpen(!isOpen)}}>
              { isOpen ? (<Icon>menu_open</Icon>) : (<Icon>menu</Icon>) }
            </span>
          </div>
        )}
        { !auth.user && !isLogin && (
          <Link to="/login" className={style.login}>
            login
            <Icon title="Login">login</Icon>
          </Link>
        )}

        {feeds.length > 1 && (
          <div className={style.select}>
            <span className={style.selectLabel}>Feeds:</span>
            <div className={style.selectWrap}>
              <Select
                isSearchable={false}
                onChange={event => callback(event)}
                options={feeds}
                value={active}
                styles={filterStyleHeader}
              />
            </div>
          </div>
        )}
      </div>

      <menu className={menuClasses.join(' ')}>
        <div>
          {feeds.length > 1 && (
            <div className={style.select}>
              <div className={style.selectLabel}>
                <Icon>swap_horizontal_circle</Icon>
                <span>Change Feed</span>
              </div>
              <Select
                isSearchable={false}
                onChange={event => handleSelectChange(event)}
                options={feeds}
                value={active}
                styles={filterStyleHeader}
                />
            </div>
          )}

          { auth.user && (
            <button className={style.logout} onClick={handleLogout}>
              {isLoading ? <MiniSpinner color='white'/> : <span><Icon title="Logout">logout</Icon> logout</span>}
            </button>
          )}
        </div>
      </menu>
    </header>
  )
}

SiteHeader.propTypes = {
  active: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  feeds: PropTypes.array.isRequired
}

export default SiteHeader
