export const filterStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '0',
    border: 'none'
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '0'
  })
}

export const filterStyleHeader = {
  // container: (provided) => ({
  //   ...provided,
  //   width: '150px',
  //   marginLeft: '10px'
  // }),
  control: (provided) => ({
    ...provided,
    borderRadius: '0',
    borderWidth: '2px',
    borderColor: '#373737',
    background: '#000000',
    cursor: 'pointer'
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '0',
    background: '#000000'
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#ffffff'
  }),
  option: (provided, state) => {
    let background = 'transparent'
    if (state.isFocused) {
      background = '#373737'
    }
    if (state.isSelected) {
      background = '#2684FF'
    }
    return {
      ...provided,
      background: background,
      cursor: 'pointer'
    }
  },
  indicatorSeparator: () => ({
    display: 'none'
  })
}



// export const filterStyles = {
//   container: (provided) => ({
//     ...provided,
//     flex: '1 0 auto',
//     display: 'inline-block',
//     width: 'calc(100% - 22px)',
//   }),
//   control: (provided) => ({
//     ...provided,
//     backgroundColor: 'transparent',
//     border: 'none',
//     boxShadow: 'none',
//     cursor: 'pointer',
//   }),
//   menu: (provided) => {
//     const mq = window.matchMedia('(max-width: 767px)')
//     if (mq.matches) {
//       return {
//         ...provided,
//         borderRadius: '0px',
//         minWidth: '190px',
//         left: '-50px'
//       }
//     }
//     return {
//       ...provided,
//       borderRadius: '0px',
//     }
//   },
//   valueContainer: (provided) => ({
//     ...provided,
//     padding: '6px 8px',
//     lineHeight: '1',
//   }),
//   singleValue: (provided) => ({
//     ...provided,
//     // color: theme === 'dark' ? 'white' : 'black',
//     textTransform: 'uppercase',
//     fontVariationSettings: "'wght' 500, 'wdth' 400"
//   }),
//   option: (provided, state) => {
//     let backgroundColor = 'white'
//     let color = 'black'
//     if (state.isFocused) {
//       backgroundColor = '#ededed'
//     }
//     if (state.isSelected) {
//       backgroundColor = '#df1995'
//       color = 'white'
//     }

//     return {
//       ...provided,
//       cursor: 'pointer',
//       color: color,
//       padding: '15px 20px',
//       lineHeight: '1',
//       backgroundColor: backgroundColor,
//       fontVariationSettings: "'wght' 500, 'wdth' 400"
//     }
//   },
//   indicatorSeparator: () => ({
//     display: 'none'
//   })
// }