import React, { useState, useContext, createContext } from 'react'
import { SPEEDBAG_API } from '../MAGIC_URLS'
import axios from 'axios'

const instance = axios.create({
  baseURL: SPEEDBAG_API,
  headers: {'Content-Type': 'application/json'}
})

const authContext = createContext()

// Provider component that wraps your app and makes auth object
// available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth()

  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  )
}

// Hook for child components to get the auth object and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext)
}

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(JSON.parse(sessionStorage.getItem('user')) || undefined)
  const [authorization, setAuthorization] = useState(JSON.parse(sessionStorage.getItem('authorization')) || undefined)

  function setUserData(auth, {user, active_feeds, available_feeds}) {
    // Take user data from server and transform it into what we need
    const userData = {
      id: user.id,
      email: user.email
    }
    userData.feeds = active_feeds.map(feed => {
      return {
        value: feed.key,
        label: feed.name
      }
    })

    // Save user data from server
    setUser(userData)
    sessionStorage.setItem('user', JSON.stringify(userData))

    // Save auth token from server
    setAuthorization(auth)
    sessionStorage.setItem('authorization', JSON.stringify(auth))
  }

  function signin(email, password) {
    console.log('signing in')
    return new Promise((resolve, reject) => {
      let auth
      instance.post(`users/sign_in`, {user: {email, password}})
        .then(response => response ? auth = response.headers.authorization : resolve(false))
        .then(() => instance.get(`feeds`, {headers: {'Authorization': auth}}))
        .then(response => setUserData(auth, response.data))
        .catch(() => reject('Bad username or password'))
    })
  }

  function signout() {
    return instance.delete(`users/sign_out`, {headers: {'Authorization': authorization}})
      .then(() => {
        sessionStorage.removeItem('authorization')
        sessionStorage.removeItem('user')
        setAuthorization(false)
        setUser(false)
      })
  }

  function forgot(email) {
    console.log(email)
    return instance.post(`users/password`, {user: {email}})
  }
  // Return the user object and auth methods
  // return {user, signin, signout}
  return {user, signin, signout, forgot}
}
