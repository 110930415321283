import React from 'react'

const NoMatch = () => {
  return (
    <section>
      <div className="container">
        <h2>NoMatch</h2>
        <p>No match, we have no match today</p>
      </div>
    </section>
  )
}

export default NoMatch
