import React, { useState } from 'react'
import PropTypes from 'prop-types'
import style from './Hashtags.module.scss'

const VISIBLE_INCREMENT = 10

const Hashtags = ({ tags }) => {
  const [visible, setVisible] = useState(VISIBLE_INCREMENT)

  return (
    <div className={style.wrap}>
      <h3>Top Hashtags</h3>
      {tags
        .slice(0, visible)
        .map(tag => <Hashtag {...tag} key={tag.tag} />)
      }
      {visible < tags.length && (
        <button className={style.loadMore} onClick={() => setVisible(visible + VISIBLE_INCREMENT)}>more</button>
      )}
    </div>
  )
}

Hashtags.propTypes = {
  tags: PropTypes.array.isRequired
}

const Hashtag = ({ tag, count }) => {
  return (
    <a href={`https://twitter.com/hashtag/${tag.slice(1)}`} className={style.item} target="_blank" rel="noopener noreferrer">
      <strong>{tag}</strong>
      {/* ({count}) */}
    </a>
  )
}

Hashtag.propTypes = {
  tag: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired
}

export default Hashtags
