import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useAuth } from './auth'
import { MiniSpinner } from '../components/Spinners'

const Signin = ({ callback }) => {
  let auth = useAuth()
  const [isLoading, setIsLoading] = useState(auth.user)
  const [message, setMessage] = useState(null)

  const handleSubmit = event => {
    event.preventDefault()
    const user = event.target.user.value
    const pass = event.target.pass.value

    if (user && pass) {
      setIsLoading(true)
      auth.signin(user, pass)
        .catch(message => setMessage(message))
        .finally(() => setIsLoading(false))
    } else {
      setMessage('Please enter both username and password')
    }
  }

  return (
    <div className="container">
      <h2>Sign In</h2>
      <p>Sign in, we have sign  in today</p>
      <form onSubmit={handleSubmit}>
        <input type="text" name="user" placeholder="username" />
        <input type="password" name="pass" placeholder="password" />
        <p>{message}</p>
        <button type="submit">
          {isLoading ? <MiniSpinner/> : 'Sign in'}
        </button>
      </form>
      <button onClick={callback}>Forgot your password?</button>
    </div>
  )
}

Signin.propTypes = {
  callback: PropTypes.func.isRequired
}

export default Signin
