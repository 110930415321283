import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import Sticky from 'react-stickynode'
import Filters from '../components/Filters'
import Hashtags from '../components/Hashtags'
import BackToTop from '../components/BackToTop'
import Tweets from '../components/Tweets'
import { filterStyles } from '../filterStyles'
import style from './Speedbag.module.scss'

const Speedbag = ({ data }) => {
  const [teams, setTeams] = useState(getTeams())
  const [activeTeams, setActiveTeams] = useState([])

  // Munge whatever tweets and tags we're supposed to care about looking at
  // based on whatever we have selected at the moment.
  function getTeams(selectedTeams = []) {
    const teamIds = selectedTeams.map(team => team.value)
    const teams = teamIds.length ? data.filter(team => teamIds.includes(team.id)) : data

    let aggregate = teams.reduce((agg, team) => {
      // Tweets may be dupes, same tweet may live under both "travel" and "culture" for example.
      // Transfter the team info into the tweet for rendering.
      team.top_retweets.forEach(tweet => {
        const exists = agg.tweets.find(t => t.tweet_id === tweet.tweet_id)
        if (!exists) {
          agg.tweets.push(Object.assign(tweet, {
            name: team.team_name,
            icon: team.icon,
            color: team.color,
          }))
        }
      })

      // Count up popularity of each tag so we can sort on it later
      Object.keys(team.top_hashtags).map(tag => {
        const exists = agg.tags.find(t => t.tag === tag)
        const count = team.top_hashtags[tag]
        return exists ? exists.count += count : agg.tags.push({tag, count})
      })

      return agg
    }, {tweets:[], tags:[]})

    // Do the sorting
    aggregate.tweets.sort((a, b) => b.retweet_count - a.retweet_count)
    aggregate.tags.sort((a, b) => b.count - a.count)

    return aggregate
  }

  // Clicking filters in the sidebar returns an object
  function filterClick({ id, team_name }) {
    // Create an object for react-select
    const nextTeam = {
      value: id,
      label: team_name
    }

    // Remove the clicked filter from the array of active filters
    let nextTeams = activeTeams.filter(team => JSON.stringify(team) !== JSON.stringify(nextTeam))
    // If new and old arrays match, we didn't remove anything, so add this as a filter
    if (nextTeams.length === activeTeams.length) {
      nextTeams.push(nextTeam)
    }

    setTeams(getTeams(nextTeams))
    setActiveTeams(nextTeams)
  }

  // Using the dropdown filter returns an array of objects
  function filterBlur(activeTeams) {
    setTeams(getTeams(activeTeams))
    setActiveTeams(activeTeams)
  }

  return (
    <div className={style.wrap}>
      <nav className={style.nav}>
        <em>Showing content for:</em>
        <Select
          isMulti
          onChange={filterBlur}
          placeholder='All Topics'
          options={data.map(team => ({value: team.id, label: team.team_name}))}
          value={activeTeams}
          styles={filterStyles}
        />
      </nav>
      <section className={style.primary}>
        <Tweets tweets={teams.tweets} />
      </section>
      <aside className={style.secondary}>
        <Hashtags tags={teams.tags} />
        <Sticky top={64}>
          <Filters teams={data} activeTeams={activeTeams} callback={filterClick} />
          <BackToTop/>
        </Sticky>
      </aside>
    </div>
  )
}

Speedbag.propTypes = {
  data: PropTypes.array.isRequired
}

export default Speedbag
